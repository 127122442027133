<script setup lang="ts">
import type { MediaProps } from '@/interfaces';

const props = withDefaults(
  defineProps<MediaProps>(),
  {
    loading: 'lazy',
    alt: 'Image',
    format: 'webp',
    preload: false,
  },
)

const error = ref(false);

const onError = () => (error.value = true);
</script>

<template>
  <div class="media">
    <nuxt-img
      v-if="image && !error"
      :alt="alt"
      :src="image"
      :sizes="sizes"
      :format="format"
      :preload="preload"
      :loading="loading"
      :width="width"
      :height="height"
      @error="onError"
    ></nuxt-img>
    <nuxt-img v-else src="/images/common/plug.jpg" alt="Error Image"></nuxt-img>
  </div>
</template>

<style scoped lang="scss">
.media {
  width: inherit;
  height: inherit;
  border-radius: inherit;
  aspect-ratio: inherit;
  img {
    width: inherit;
    height: inherit;
    border-radius: inherit;
    aspect-ratio: inherit;
    object-fit: cover;
  }
}
</style>
